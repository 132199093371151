//
// Pages / Sign Up
//
// --------------------------------------------------


// ************************************************************************* //
//**   Small screens (mobile first)                                        **//

html body.page-signup {
  background: #666 !important;
}


// Page background
//

#page-signup-bg {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  // Image
  img {
    width: 100%;
  }

  // BG overlay
  .overlay {
    background: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}


// Sign Up page
//

.page-signup {
  
  // Container
  .signup-container {
    border-radius: $border-radius-base;
    display: block;
    margin: 20px 14px;
    position: relative;
    z-index: 999;
  }

  // Left side
  //

  .signup-header {
    background: #444;
    display: block;
    overflow: hidden;
    padding: 20px 30px;
    text-align: center;

    .logo {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      opacity: 1;

      &[href]:hover {
        opacity: .7;
      }
    }

    .slogan {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      margin-top: 5px;
    }
  }

  // Form
  //

  .signup-form {
    background: #fff;
    display: block;
    padding: 30px 20px;
    position: relative;
  }

    // Form buttons and links
  .form-actions {
    margin-top: 15px;

    // Submit button
    .signup-btn {
      border: none;
      border-radius: 999999px;
      font-size: 13px;
      font-weight: 600;
      line-height: 19px;
      outline: 0 !important;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      @include transition(all .3s);

      &:hover {
        background: #444 !important;
      }
    }

    // Reset password link
    a.forgot-password {
      border-bottom: 1px dashed #bbb;
      color: #999;
      display: inline-block;
      font-size: 11px;
      margin-left: 10px;
      padding-bottom: 1px;

      &:hover {
        color: $text-color;
        text-decoration: none;
      }
    }
  }


  // Form elements
  //

  .form-group {
    margin: 0;
    position: relative;
  }

  .form-group + .form-group {
    margin: 10px 0 0 0;
  }

  .form-group.w-icon {
    .signup-form-icon {
      border-right: 1px solid $input-border;
      bottom: 9px;
      color: $input-border;
      display: block;
      font-size: 14px;
      left: 0;
      line-height: 27px;
      position: absolute;
      text-align: center;
      top: 9px;
      width: $signin-form-group-w-icon-width;
      z-index: 1002;
      @include transition(all .3s);
    }

    input[type="text"],
    input[type="password"] {
      font-size: 15px;
      padding-left: $signin-form-group-w-icon-width + $padding-large-horizontal;

      &:focus + .signup-form-icon {
        color: #888;
      }
    }
  }


  // Form header text
  //

  .signup-text {
    color: #777;
    display: block;
    font-size: 15px;
    font-style: italic;
    margin-bottom: 25px;
    position: relative;
    text-align: center;

    span {
      background: #fff;
      display: inline-block;
      padding: 0 20px;
      position: relative;
    }

    &:before {
      background: $table-border-color;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }


  // "Sign In with" block

  .signup-with {
    background: #f4f4f4;
    border-top: 1px solid #ddd;
    margin: 30px (-20px) (-30px) (-20px);
    padding: 20px 20px 20px 20px;
  }


  // Social button
  .signup-with-btn {
    border-radius: 2px;
    color: #fff;
    display: block;
    font-weight: 300;
    padding: 10px 0;
    text-align: center;
    @include transition(all .3s);

    span {
      font-weight: 600;
    }

    & + .signup-with-btn {
      margin-top: 10px;
    }

    &:hover {
      background: #444 !important;
    }
  }


  // Sign In link
  //

  .have-account {
    color: #fff;
    display: block;
    font-size: 14px;
    margin: (-20px) 0 0 0;
    padding: 20px 0;
    position: relative;
    text-align: center;
    z-index: 999;

    a {
      color: #fff;
      text-decoration: underline;
      @include transition(all .3s);

      &:hover {
        opacity: .6;
      }
    }
  }
}

@media (min-width: $screen-small) {
  .page-signup {
    .signup-container {
      margin-left: auto;
      margin-right: auto;
      width: 380px;
      margin: 80px auto;
    }

    .signup-form {
      padding: 30px;
    }

    .signup-with {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
    }

    // Sign In link
    //

    .have-account {
      margin: (-80px) 0 20px;
    }
  }
}