//
// Pages / Sign In
//
// --------------------------------------------------


// ************************************************************************* //
//**   Small screens (mobile first)                                        **//

html body.page-signin {
  background: #666 !important;
}


// Page background
//

#page-signin-bg {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  // Image
  img {
    width: 100%;
  }

  // BG overlay
  .overlay {
    background: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}


// Sign In page
//

.page-signin {
  
  // Container
  .signin-container {
    border-radius: $border-radius-base;
    display: block;
    margin: 20px 14px;
    position: relative;
    z-index: 999;
  }

  
  // Left side
  //

  .signin-info {
    background: #444;
    display: block;
    overflow: hidden;
    padding: 20px 30px;
    text-align: center;

    .logo {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      opacity: 1;

      &[href]:hover {
        opacity: .7;
      }
    }

    .slogan {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      margin-top: 5px;
    }

    ul {
      display: none;
    }
  }

  
  // Right side
  //

  .signin-form {
    background: #fff;
    display: block;
    padding: 30px;
    position: relative;
  }


  // Password reset form
  //

  .password-reset-form {
    background: #fff;
    bottom: 0;
    display: none;
    left: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1020;

    // Form header
    .header {
      background: #f4f4f4;
      border-bottom: 1px solid #ddd;
      margin: (-30px) (-30px) 30px (-30px);
      padding: 20px 20px;

      // Header text
      .signin-text {
        margin: 0;

        span {
          background: #f4f4f4;
        }

        &:before {
          display: none;
        }
      }

      // Close btn
      .close {
        margin-top: 2px;
      }
    }

    .form-group.w-icon .signin-form-icon {
      z-index: 1025;
    }
  }

  // Form buttons and links
  .form-actions {
    margin-top: 15px;

    // Submit button
    .signin-btn {
      border: none;
      border-radius: 999999px;
      font-size: 13px;
      font-weight: 600;
      line-height: 19px;
      outline: 0 !important;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      @include transition(all .3s);

      &:hover {
        background: #444 !important;
      }
    }

    // Reset password link
    a.forgot-password {
      border-bottom: 1px dashed #bbb;
      color: #999;
      display: inline-block;
      font-size: 11px;
      margin-left: 10px;
      padding-bottom: 1px;

      &:hover {
        color: $text-color;
        text-decoration: none;
      }
    }
  }


  // Form elements
  //

  .form-group {
    margin: 0;
    position: relative;
  }

  .form-group + .form-group {
    margin: 10px 0 0 0;
  }

  .form-group.w-icon {
    .signin-form-icon {
      border-right: 1px solid $input-border;
      bottom: 9px;
      color: $input-border;
      display: block;
      font-size: 14px;
      left: 0;
      line-height: 27px;
      position: absolute;
      text-align: center;
      top: 9px;
      width: $signin-form-group-w-icon-width;
      z-index: 1002;
      @include transition(all .3s);
    }

    input[type="text"],
    input[type="password"] {
      font-size: 15px;
      padding-left: $signin-form-group-w-icon-width + $padding-large-horizontal;

      &:focus + .signin-form-icon {
        color: #888;
      }
    }
  }


  // Form header text
  //

  .signin-text {
    color: #777;
    display: block;
    font-size: 15px;
    font-style: italic;
    margin-bottom: 25px;
    position: relative;
    text-align: center;

    span {
      background: #fff;
      display: inline-block;
      padding: 0 20px;
      position: relative;
    }

    &:before {
      background: $table-border-color;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }


  // "Sign In with" block

  .signin-with {
    background: #f4f4f4;
    border-top: 1px solid #ddd;
    margin: 30px (-30px) (-30px) (-30px);
    padding: 20px 30px 20px 30px;
  }


  // Social button
  .signin-with-btn {
    border-radius: 2px;
    color: #fff;
    display: block;
    font-weight: 300;
    padding: 10px 0;
    text-align: center;
    @include transition(all .3s);

    span {
      font-weight: 600;
    }

    & + .signin-with-btn {
      margin-top: 10px;
    }

    &:hover {
      background: #444 !important;
    }
  }


  // Sign up link
  //

  .not-a-member {
    color: #fff;
    display: block;
    font-size: 14px;
    margin: (-20px) 0 0 0;
    padding: 20px 0;
    position: relative;
    text-align: center;
    z-index: 999;

    a {
      color: #fff;
      text-decoration: underline;
      @include transition(all .3s);

      &:hover {
        opacity: .6;
      }
    }
  }
}


@media (min-width: $screen-small) {
  .page-signin {
    .form-actions {
      margin-top: 15px;

      // Submit button
      .signin-btn {
        padding-left: 40px;
        padding-right: 40px;
        width: auto;
      }
    }
  }
}


// ************************************************************************* //
//**   Desktops                                                            **//

@media (min-width: $screen-tablet) {
  .page-signin {

    // Container
    //
  
    .signin-container {
      display: table;
      margin: 80px auto;
      width: 650px;
      table-layout: fixed;
    }

    // Left side
    //

    .signin-info {
      display: table-cell;
      padding: 30px;
      text-align: left;
      vertical-align: top;
      width: 40%;

      // Features list
      //

      ul {
        color: #fff;
        display: block;
        font-size: 13px;
        font-weight: 300;
        margin: 30px 0 0 0;
        padding: 0;

        li {
          list-style: none;
          margin: 0;
          padding: 7px 0;
        }

        .signin-icon {
          display: block;
          float: left;
          font-size: 14px;
          line-height: 18px;
          margin-right: 6px;
          text-align: center;
          width: 24px;
        }
      }
    }

    // Right side
    //

    .signin-form {
      display: table-cell;
      vertical-align: top;
      width: 60%;
    }

    // Sign Up link
    //

    .not-a-member {
      margin: (-80px) 0 80px;
    }
  }
}
