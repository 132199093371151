//
// Pages / Invoice
//
// --------------------------------------------------


// Mobile first
//

.page-invoice {

  // Base

  .invoice {
    padding: 0 20px;

    hr {
      border: none;
      border-bottom: 3px solid #f1f1f1;
      margin: 0 -20px;
    }
  }

  // Header

  .invoice-header {
    background: #f7f7f7;
    border-bottom: 8px solid #f1f1f1;
    margin: 0 -20px;
    padding: 20px;
    position: relative;
    @include clearfix();

    h3 {
      font-size: 19px;
      font-weight: 600;
      height: 55px;
      line-height: 23px;
	  margin: 0;
      padding: 0;
      word-wrap: none;
    }

    small {
      color: #a0a0a0;
      font-size: 12px;
    }

    .invoice-logo {
      display: block;
      height: 26px;
      width: 26px;
      margin: 12px 0 0 -40px;
      position: absolute;
    }

    address {
      border-left: 5px solid #e8e8e8;
      color: #959595;
      display: block;
      font-size: 12px;
      line-height: 15px;
      padding-left: 10px;
      margin-top: 5px;
    }
  }

  .invoice-date {
    font-size: 16px;
    font-weight: 600;
    margin-top: -5px;
  }

  // Information

  .invoice-info,
  .invoice-table {
    padding: 30px 0;
    @include clearfix();
  }

  .invoice-recipient {
    font-size: 14px;
    line-height: 19px;
    height: 60px;
  }

  .invoice-total {
    background: #f7f7f7;
    color: #888888;
    font-weight: 600;
    height: 60px;
    line-height: 60px;
    padding: 0 25px;
    white-space: nowrap;
    margin-top: 20px;

    span {
      display: block;
      float: right;
      color: $text-color;
      font-size: 24px;
    }
  }

  // Table

  .invoice-description {
    color: #aaa;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding-top: 4px;
  }

  table {
    width: 100%;

    thead {
      background: #f7f7f7;
      color: #888;
      font-size: 12px;
    }

    tbody tr {
      font-size: 14px;

      & + tr {
        border-top: 1px solid #f1f1f1;
      }
    }

    th,
    td {
      border-left: 1px solid #fff;
      font-weight: 600;
      padding: 7px 0 7px 15px;
      text-align: left;
      vertical-align: top;
    }

    td {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
}


// Desktops
//

@media (min-width: $screen-tablet) {
  .page-invoice {

    // Base

    .invoice {
      padding: 0 40px;

      hr {
        border: none;
        border-bottom: 3px solid #f1f1f1;
        margin: 0 -40px;
      }
    }

    // Header

    .invoice-header {
      padding: 40px;
      margin: 0 -40px;

      h3 {
        float: left;
      }

      address {
        margin: 6px 0 0 30px;
        float: left;
      }
    }

    .invoice-date {
      float: right;
      margin-top: 6px;
    }

    // Information

    .invoice-recipient,
    .invoice-total {
      width: 50%;
    }

    .invoice-recipient {
      float: left;
    }

    .invoice-total {
      float: right;
      margin-top: 0;
    }
  }
}


// Print version
//

body.page-invoice.page-invoice-print,
body.page-invoice.page-invoice-print * {
  color: #000000 !important;
}

.page-invoice.page-invoice-print {

  // Base

  .invoice {
    min-width: $screen-tablet;
    padding: 0 40px;

    hr {
      border-bottom: 1px solid #aaa;
      margin: 0 -40px;
    }
  }

  // Header

  .invoice-header {
    padding: 40px;
    margin: 0 -40px;
    background: none;
    border-bottom: 2px solid #aaa;

    h3 {
      float: left;
      margin: 0 !important;
    }

    address {
      margin: 6px 0 0 30px;
      float: left;
    }

    .invoice-logo {
      display: none !important;
    }
  }

  .invoice-date {
    float: right;
    margin-top: 6px;
  }

  // Information

  .invoice-recipient,
  .invoice-total {
    width: 50%;
  }

  .invoice-recipient {
    float: left;

    &:before {
      color: #000;
      background: none;
    }
  }

  .invoice-total {
    float: right;
    margin-top: 0;
    background: none;
    text-align: right;

    span {
      margin-left: 15px;
    }
  }

  // Table

  table {
    thead {
      background: none;
      border-bottom: 1px solid #aaa;
    }
  }
}
